<template>
	<div>
		<div class="row">
			<div class="col-lg-6">
				<div class="form-group">
					<label class="req"> Quantity </label>
					<div class="input-group mb-3">
						<input
							v-model="formData.quantity"
							type="number"
							class="form-control"
						/>
						<div class="input-group-append">
							<span class="input-group-text"> Piece(s) </span>
						</div>
					</div>
				</div>
			</div>
			<div class="col-lg-6">
				<div class="form-group">
					<label class="req"> Material </label>
					<input
						v-model="formData.material"
						type="text"
						class="form-control"
					/>
				</div>
			</div>
			<div class="col-lg-4">
				<div class="form-group">
					<label class="req"> Profile length </label>
					<div class="input-group mb-3">
						<input
							v-model="formData.profileLength"
							type="number"
							class="form-control"
							step="0.01"
						/>
						<div class="input-group-append">
							<span class="input-group-text"> mm </span>
						</div>
					</div>
				</div>
			</div>
			<div class="col-lg-4">
				<div class="form-group">
					<label class="req"> Width </label>
					<div class="input-group mb-3">
						<input
							v-model="formData.width"
							type="number"
							class="form-control"
							step="0.01"
						/>
						<div class="input-group-append">
							<span class="input-group-text"> mm </span>
						</div>
					</div>
				</div>
			</div>
			<div class="col-lg-4">
				<div class="form-group">
					<label class="req"> Slot </label>
					<div class="input-group mb-3">
						<input
							v-model="formData.slot"
							type="number"
							class="form-control"
							step="0.01"
						/>
						<div class="input-group-append">
							<span class="input-group-text"> mm </span>
						</div>
					</div>
				</div>
			</div>
			<div class="col-12">
				<div class="form-group">
					<label class="req">Construction</label>
					<select
						v-model="formData.constructionType"
						class="form-control"
					>
						<option value="flat">Flat</option>
						<option value="curved">Curved</option>
					</select>
				</div>
			</div>
			<div class="col-12">
				<div
					v-if="formData.constructionType == 'curved'"
					class="form-group"
				>
					<label class="req"> Radius </label>
					<div class="input-group mb-3">
						<input
							v-model="formData.radius"
							type="number"
							class="form-control"
							step="0.01"
						/>
						<div class="input-group-append">
							<span class="input-group-text"> mm </span>
						</div>
					</div>
				</div>
			</div>
			<div class="col-lg-4">
				<div class="form-group">
					<label>
						Profile wire
						<a
							href="/products/wedge-wire/#support-profiles"
							target="_blank"
						>
							<i class="fa fa-info-circle ml-1"></i>
						</a>
					</label>
					<input
						v-model="formData.profileWire"
						type="text"
						class="form-control"
					/>
				</div>
			</div>
			<div class="col-lg-4">
				<div class="form-group">
					<label>
						Support wire
						<a
							href="/products/wedge-wire/#surface-profiles"
							target="_blank"
						>
							<i class="fa fa-info-circle ml-1"></i>
						</a>
					</label>
					<input
						v-model="formData.supportWire"
						type="text"
						class="form-control"
					/>
				</div>
			</div>
			<div class="col-lg-4">
				<div class="form-group">
					<label> Pitch </label>
					<div class="input-group mb-3">
						<input
							v-model="formData.pitch"
							type="number"
							class="form-control"
							step="0.01"
						/>
						<div class="input-group-append">
							<span class="input-group-text"> mm </span>
						</div>
					</div>
				</div>
			</div>
		</div>

		<h4>Accessories</h4>

		<div class="row">
			<div class="col-lg-6">
				<div class="form-group">
					<label> Parallel to profile wire </label>
					<input
						v-model="formData.parallelToProfileWire"
						type="text"
						class="form-control"
					/>
				</div>
			</div>
			<div class="col-lg-6">
				<div class="form-group">
					<label> Perpendicular to profile wire </label>
					<input
						v-model="formData.perpendicularToProfileWire"
						type="text"
						class="form-control"
					/>
				</div>
			</div>
		</div>

		<div class="form-group">
			<label> Remarks </label>
			<textarea
				v-model="formData.remarks"
				rows="3"
				class="form-control"
			/>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		value: {
			type: [Object, null],
			default() {
				return {}
			},
		},
	},
	data() {
		return {
			formData: {
				quantity: null,
				material: null,
				profileLength: null,
				width: null,
				slot: null,
				constructionType: null,
				radius: null,
				profileWire: null,
				supportWire: null,
				pitch: null,
				parallelToProfileWire: null,
				perpendicularToProfileWire: null,
				remarks: null,
			},
		}
	},
	watch: {
		formData: {
			deep: true,
			handler(newVal, oldVal) {
				if (oldVal.constructionType !== newVal.constructionType) {
					this.formData.radius = null
				}

				this.$emit('input', newVal)
			},
		},
	},
}
</script>