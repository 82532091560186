<template>
	<div>
		<h3>Flat screen</h3>

		<calculation-credits />

		<calculation-result :result="calculation"> </calculation-result>

		<form-feedback :feedback="feedback" />

		<!-- Form -->
		<form
			v-if="
				user &&
				(user.calculation_credits > 0 ||
					user.calculation_credits === null)
			"
			@submit.prevent="calculate"
		>
			<div class="form-group">
				<label>Quantity</label>
				<input
					v-model="formData.quantity"
					type="number"
					class="form-control"
				/>
			</div>

			<div class="row">
				<div class="col-lg-6">
					<div class="form-group">
						<label>Surface profile</label>
						<select
							v-model="formData.surfaceProfile"
							class="form-control"
						>
							<option
								v-for="(
									surfaceProfile, surfaceProfileIndex
								) in surfaceProfiles"
								:key="surfaceProfileIndex"
								:value="surfaceProfile.uuid"
							>
								{{ surfaceProfile.name }}
							</option>
						</select>
					</div>
				</div>
				<div class="col-lg-6">
					<div class="form-group">
						<label>Surface material</label>
						<select
							v-model="formData.surfaceMaterial"
							class="form-control"
							:disabled="formData.surfaceProfile == null"
						>
							<option
								v-for="(
									surfaceMaterial, surfaceMaterialIndex
								) in surfaceMaterials"
								:key="surfaceMaterialIndex"
								:value="surfaceMaterial.uuid"
							>
								{{ surfaceMaterial.internal_name }}
							</option>
						</select>
					</div>
				</div>
				<div class="col-12">
					<div class="form-group">
						<label>Curved?</label>
						<select v-model="formData.curved" class="form-control">
							<option :value="true">Yes</option>
							<option :value="false">No</option>
						</select>
					</div>
				</div>
				<div class="col-lg-6">
					<div class="form-group">
						<label>Support profile</label>
						<select
							v-model="formData.supportProfile"
							class="form-control"
							:disabled="formData.surfaceProfile == null"
						>
							<option
								v-for="(
									supportProfile, supportProfileIndex
								) in supportProfiles"
								:key="supportProfileIndex"
								:value="supportProfile.uuid"
							>
								{{ supportProfile.name }}
							</option>
						</select>
					</div>
				</div>
				<div class="col-lg-6">
					<div class="form-group">
						<label>Support material</label>
						<select
							v-model="formData.supportMaterial"
							class="form-control"
							:disabled="formData.supportProfile == null"
						>
							<option
								v-for="(
									supportMaterial, supportMaterialIndex
								) in supportMaterials"
								:key="supportMaterialIndex"
								:value="supportMaterial.uuid"
							>
								{{ supportMaterial.internal_name }}
							</option>
						</select>
					</div>
				</div>
				<div class="col-lg-6">
					<div class="form-group">
						<label>Slot width</label>
						<div class="input-group">
							<input
								v-model="formData.slotWidth"
								type="number"
								class="form-control"
								step="0.01"
							/>
							<div class="input-group-append">
								<span class="input-group-text">mm</span>
							</div>
						</div>
					</div>
				</div>
				<div class="col-lg-6">
					<div class="form-group">
						<label>Profile length</label>
						<div class="input-group">
							<input
								v-model="formData.length"
								type="number"
								class="form-control"
								step="0.01"
							/>
							<div class="input-group-append">
								<span class="input-group-text">mm</span>
							</div>
						</div>
					</div>
				</div>
				<div class="col-lg-6">
					<div class="form-group">
						<label>Width</label>
						<div class="input-group">
							<input
								v-model="formData.width"
								type="number"
								class="form-control"
								step="0.01"
							/>
							<div class="input-group-append">
								<span class="input-group-text">mm</span>
							</div>
						</div>
					</div>
				</div>
				<div class="col-lg-6">
					<div class="form-group">
						<label>Pitch</label>
						<div class="input-group">
							<input
								v-model="formData.pitch"
								type="number"
								class="form-control"
								step="0.01"
							/>
							<div class="input-group-append">
								<span class="input-group-text">mm</span>
							</div>
						</div>
					</div>
				</div>
			</div>
			<button
				:disabled="calculating"
				class="btn btn-primary"
				type="submit"
			>
				Calculate
			</button>
		</form>
		<!-- End Form -->
	</div>
</template>

<script>
import CalculationCredits from '../components/CalculationCredits.vue'

export default {
	components: { CalculationCredits },
	computed: {
		user() {
			return this.$store.getters.user
		},

		chosenSurfaceMaterial() {
			return this.formData.surfaceMaterial
		},

		chosenSupportMaterial() {
			return this.formData.supportMaterial
		},

		chosenSurfaceProfile() {
			return this.formData.surfaceProfile
		},

		chosenSupportProfile() {
			return this.formData.supportProfile
		},

		chosenDiameter() {
			return this.formData.diameter
		},
	},
	watch: {
		chosenSurfaceProfile(newVal) {
			this.formData.surfaceMaterial = null
			this.formData.supportProfile = null

			if (newVal) {
				this.fetchSurfaceMaterials()
				this.fetchSupportProfiles()
			}
		},
		chosenSupportProfile(newVal) {
			this.formData.supportMaterial = null

			if (newVal) {
				this.fetchSupportMaterials()
			}
		},
		formData: {
			deep: true,
			handler() {
				this.calculation = null
			},
		},
	},
	data() {
		return {
			calculating: false,
			formData: {
				curved: null,
				quantity: null,
				surfaceProfile: null,
				supportProfile: null,
				surfaceMaterial: null,
				supportMaterial: null,
				supportQuantity: null,
				slotWidth: null,
				length: null,
				width: null,
				pitch: null,
			},
			surfaceProfiles: null,
			supportProfiles: null,
			surfaceMaterials: null,
			supportMaterials: null,
			supportQuantities: null,
			calculation: null,
			feedback: {
				context: null,
				message: null,
				errors: [],
			},
			result: null,
		}
	},
	mounted() {
		this.fetchSurfaceProfiles()
	},
	methods: {
		calculate() {
			if (this.calculating) {
				return
			}

			this.calculating = true

			this.$http
				.post('/calculators/flat-screen', {
					surface_profile: this.formData.surfaceProfile,
					surface_material: this.formData.surfaceMaterial,
					support_profile: this.formData.supportProfile,
					support_material: this.formData.supportMaterial,
					length: this.formData.length,
					width: this.formData.width,
					slot_width: this.formData.slotWidth,
					pitch: this.formData.pitch,
					curved: this.formData.curved,
					quantity: this.formData.quantity,
					type: 'flatscreen',
				})
				.then((res) => {
					this.feedback.context = null
					this.feedback.message = null
					this.feedback.errors = []
					this.calculation = res.data.calculation
					this.calculating = false
				})
				.catch((err) => {
					this.feedback.context = 'danger'
					this.feedback.message = err.response.data.message
					this.feedback.errors = err.response.data.errors
					this.calculating = false
				})
		},

		fetchSurfaceProfiles() {
			this.$http
				.get(
					`/surface-profiles?filter[eligible_for_product]=flatscreen`
				)
				.then((res) => {
					this.surfaceProfiles = res.data.data
				})
				.catch((err) => {
					console.log(err)
					alert('Could not fetch surface profiles.')
				})
		},

		fetchSupportProfiles() {
			const surfaceProfile = this.surfaceProfiles.find((profile) => {
				return profile.uuid === this.formData.surfaceProfile
			})

			if (!surfaceProfile) {
				return
			}

			this.$http
				.get(
					`/support-profiles?filter[eligible_for_surface_profile]=${surfaceProfile.internal_name}`
				)
				.then((res) => {
					this.supportProfiles = res.data.data
				})
				.catch((err) => {
					console.log(err)
					alert('Could not fetch support profiles.')
				})
		},

		fetchSurfaceMaterials() {
			this.$http
				.get(`/profiles/${this.formData.surfaceProfile}/materials`)
				.then((res) => {
					this.surfaceMaterials = res.data.data
				})
				.catch((err) => {
					console.log(err)
					alert(
						'Could not fetch surface materials for the given surface profile.'
					)
				})
		},

		fetchSupportMaterials() {
			this.$http
				.get(`/profiles/${this.formData.supportProfile}/materials`)
				.then((res) => {
					this.supportMaterials = res.data.data
				})
				.catch((err) => {
					console.log(err)
					alert(
						'Could not fetch support materials for the given support profile.'
					)
				})
		},

		fetchSupportQuantities() {
			if (!this.formData.diameter) {
				return
			}

			this.$http
				.post('/support-quantities', {
					diameter: this.formData.diameter,
				})
				.then((res) => {
					this.supportQuantities = res.data
				})
				.catch((err) => {
					console.log(err)
					alert(
						'Could not fetch support quantities for the given diameter.'
					)
				})
		},
	},
}
</script>
