<template>
    <div
        v-if="feedback && feedback.message"
        class="alert"
        :class="'alert-' + feedback.context"
    >
        {{ feedback.message }}
        <div v-if="feedback.errors" class="mt-2">
            <ul
                v-for="(items, index) in feedback.errors"
                :key="index"
                class="mb-0 mt-0"
            >
                <li v-for="(error, index2) in items" :key="index2">
                    {{ error }}
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        feedback: {
            type: Object,
            default: null,
        },
    },
}
</script>
