<template>
	<div id="app">
		<div
			v-if="user"
			class="d-flex align-items-center justify-content-between mb-8"
		>
			<h2 class="m-0">Welcome {{ user.first_name }}</h2>
			<router-link :to="{ name: 'logout' }" class="text-body"
				>Log out</router-link
			>
		</div>
		<router-view />
	</div>
</template>

<script>
export default {
	computed: {
		isAuthenticated() {
			return this.$store.getters.isAuthenticated
		},
		user() {
			return this.$store.getters.user
		},
	},
	watch: {
		isAuthenticated() {
			this.getMe()
		},
	},
	mounted() {
		if (localStorage.getItem('token')) {
			this.getMe()
		}
	},
	methods: {
		getMe() {
			this.$http
				.post('/auth/me')
				.then((res) => {
					this.$store.commit('setUser', res.data.data)
				})
				.catch((err) => {
					console.log(err)
				})
		},
	},
}
</script>
