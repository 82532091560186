<template>
	<div>
		<h3 class="mb-6">General quote request</h3>

		<form-feedback :feedback="feedback"></form-feedback>

		<form v-if="feedback.context != 'success'" @submit.prevent="send">
			<div class="form-group">
				<label>Type</label>
				<select v-model="type" class="form-control">
					<option value="screen">Screen (flat or curved)</option>
					<option value="bottom">Bottom</option>
					<option value="slot_tube">Slot tube</option>
					<option value="lateral">Lateral</option>
					<option value="nozzle">Nozzle</option>
					<option value="conical_basket">Conical basket</option>
				</select>
			</div>

			<screen-quote-form v-if="type == 'screen'" v-model="formData" />
			<bottom-quote-form v-if="type == 'bottom'" v-model="formData" />
			<slot-tube-quote-form
				v-if="type == 'slot_tube'"
				v-model="formData"
			/>
			<lateral-quote-form v-if="type == 'lateral'" v-model="formData" />
			<nozzle-quote-form v-if="type == 'nozzle'" v-model="formData" />
			<conical-basket-quote-form
				v-if="type == 'conical_basket'"
				v-model="formData"
			/>

			<button :disabled="sending" class="btn btn-primary">
				<i v-if="sending" class="fa fa-spin fa-circle-o-notch mr-1"></i>
				Request quote
			</button>
		</form>
	</div>
</template>

<script>
import ScreenQuoteForm from '../components/ScreenQuoteForm'
import BottomQuoteForm from '../components/BottomQuoteForm'
import SlotTubeQuoteForm from '../components/SlotTubeQuoteForm'
import LateralQuoteForm from '../components/LateralQuoteForm'
import NozzleQuoteForm from '../components/NozzleQuoteForm'
import ConicalBasketQuoteForm from '../components/ConicalBasketQuoteForm'

export default {
	components: {
		ScreenQuoteForm,
		BottomQuoteForm,
		SlotTubeQuoteForm,
		LateralQuoteForm,
		NozzleQuoteForm,
		ConicalBasketQuoteForm,
	},
	data() {
		return {
			type: null,
			formData: {},
			sending: false,
			feedback: {
				context: null,
				message: null,
				errors: [],
			},
		}
	},
	methods: {
		send() {
			if (this.sending) {
				return
			}

			this.sending = true

			const data = this.formData
			data.type = this.type

			this.$http
				.post('/inquiries', data)
				.then((res) => {
					console.log('Response', res)
					this.feedback.context = 'success'
					this.feedback.message = res.data.message
					this.feedback.errors = []
					this.sending = false
				})
				.catch((err) => {
					if (err.response) {
						this.feedback.context = 'danger'
						this.feedback.message = err.response.data.message
						this.feedback.errors = err.response.data.errors
					}
					this.sending = false
				})
		},
	},
}
</script>