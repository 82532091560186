<template>
	<div>
		<div class="d-flex mb-4 align-items-center justify-content-between">
			<h3 class="m-0">Login</h3>
			<router-link
				:to="{ name: 'register' }"
				class="text-body text-underline"
				>Create an account</router-link
			>
		</div>
		<form-feedback :feedback="feedback"></form-feedback>
		<form @submit.prevent="login">
			<div class="form-group">
				<label>Email address</label>
				<input
					v-model="formData.email"
					type="email"
					class="form-control"
				/>
			</div>
			<div class="form-group">
				<label>Password</label>
				<input
					v-model="formData.password"
					type="password"
					class="form-control"
				/>
			</div>
			<button :disabled="sending" type="submit" class="btn btn-primary">
				<i v-if="sending" class="fa fa-spin fa-circle-o-notch mr-1"></i>
				Login
			</button>
		</form>
	</div>
</template>

<script>
export default {
	data() {
		return {
			formData: {
				email: null,
				password: null,
			},
			sending: false,
			feedback: {
				context: null,
				message: null,
				errors: [],
			},
		}
	},
	methods: {
		login() {
			if (this.sending) {
				return
			}

			this.sending = true

			this.$http
				.post('/auth/login', this.formData)
				.then((res) => {
					this.$store.commit('login', res.data)
					this.$router.push({
						name: 'index',
					})
				})
				.catch((err) => {
					if (err.response) {
						this.feedback.context = 'danger'
						this.feedback.message = err.response.data.message
					}
					this.sending = false
				})
		},
	},
}
</script>
