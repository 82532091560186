<template>
    <div>Logout</div>
</template>

<script>
export default {
    mounted() {
        this.$store.commit('logout')
        this.$router.push({ name: 'login' })
    },
}
</script>
