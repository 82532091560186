<template>
	<div>
		<div class="row">
			<div class="col-lg-6">
				<div class="form-group">
					<label class="req"> Quantity </label>
					<input
						v-model="formData.quantity"
						type="number"
						class="form-control"
						step="1"
					/>
				</div>
			</div>
			<div class="col-lg-6">
				<div class="form-group">
					<label class="req"> Material </label>
					<input
						v-model="formData.material"
						type="text"
						class="form-control"
					/>
				</div>
			</div>
			<div class="col-lg-6">
				<div class="form-group">
					<label class="req"> Outside diameter </label>
					<div class="input-group mb-3">
						<input
							v-model="formData.outsideDiameter"
							type="number"
							class="form-control"
							step="0.01"
						/>
						<div class="input-group-append">
							<span class="input-group-text"> mm </span>
						</div>
					</div>
				</div>
			</div>
			<div class="col-lg-6">
				<div class="form-group">
					<label class="req"> Overall length </label>
					<div class="input-group mb-3">
						<input
							v-model="formData.overallLength"
							type="number"
							class="form-control"
							step="0.01"
						/>
						<div class="input-group-append">
							<span class="input-group-text"> mm </span>
						</div>
					</div>
				</div>
			</div>
			<div class="col-lg-4">
				<div class="form-group">
					<label class="req"> Filter length </label>
					<div class="input-group mb-3">
						<input
							v-model="formData.filterLength"
							type="number"
							class="form-control"
							step="0.01"
						/>
						<div class="input-group-append">
							<span class="input-group-text"> mm </span>
						</div>
					</div>
				</div>
			</div>
			<div class="col-lg-4">
				<div class="form-group">
					<label class="req"> Slot </label>
					<div class="input-group mb-3">
						<input
							v-model="formData.slot"
							type="number"
							class="form-control"
							step="0.01"
						/>
						<div class="input-group-append">
							<span class="input-group-text"> mm </span>
						</div>
					</div>
				</div>
			</div>
			<div class="col-lg-4">
				<div class="form-group">
					<label class="req"> Diameter threaded fitting </label>
					<div class="input-group mb-3">
						<input
							v-model="formData.diameterThreadedFitting"
							type="number"
							class="form-control"
							step="0.01"
						/>
						<div class="input-group-append">
							<span class="input-group-text"> mm </span>
						</div>
					</div>
				</div>
			</div>
			<div class="col-lg-6">
				<div class="form-group">
					<label>
						Profile wire
						<a
							href="/products/wedge-wire/#support-profiles"
							target="_blank"
						>
							<i class="fa fa-info-circle ml-1"></i>
						</a>
					</label>
					<input
						v-model="formData.profileWire"
						type="text"
						class="form-control"
					/>
				</div>
			</div>
			<div class="col-lg-6">
				<div class="form-group">
					<label>
						Support wire
						<a
							href="/products/wedge-wire/#surface-profiles"
							target="_blank"
						>
							<i class="fa fa-info-circle ml-1"></i>
						</a>
					</label>
					<input
						v-model="formData.supportWire"
						type="text"
						class="form-control"
					/>
				</div>
			</div>
			<div class="col-lg-4">
				<div class="form-group">
					<label class="req">Washer</label>
					<select v-model="formData.washer" class="form-control">
						<option value="Yes">Yes</option>
						<option value="No">No</option>
					</select>
				</div>
			</div>
			<div class="col-lg-4">
				<div class="form-group">
					<label class="req">Nut</label>
					<select v-model="formData.nut" class="form-control">
						<option value="Yes">Yes</option>
						<option value="No">No</option>
					</select>
				</div>
			</div>

			<div class="col-lg-4">
				<div class="form-group">
					<label class="req">Nut on top</label>
					<select v-model="formData.nutOnTop" class="form-control">
						<option value="Yes">Yes</option>
						<option value="No">No</option>
					</select>
				</div>
			</div>
			<div class="col-12">
				<div v-if="formData.nut == 'Yes'" class="form-group">
					<label> Nut size </label>
					<input
						v-model="formData.nutSize"
						type="text"
						class="form-control"
					/>
				</div>
			</div>
			<div class="col-12">
				<div v-if="formData.nutOnTop == 'Yes'" class="form-group">
					<label> Nut size on top</label>
					<input
						v-model="formData.nutOnTopSize"
						type="text"
						class="form-control"
					/>
				</div>
			</div>
			<div class="col-12">
				<div class="form-group">
					<label> Remarks </label>
					<textarea
						v-model="formData.remarks"
						rows="3"
						class="form-control"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			formData: {
				quantity: null,
				material: null,
				profileLength: null,
				width: null,
				slot: null,
				constructionType: null,
				radius: null,
				profileWire: null,
				supportWire: null,
				pitch: null,
				parallelToProfileWire: null,
				perpendicularToProfileWire: null,
				remarks: null,
			},
		}
	},
	watch: {
		formData: {
			deep: true,
			handler(newVal, oldVal) {
				if (oldVal.nut !== newVal.nut && newVal.nut === 'No') {
					this.formData.nutSize = null
				}

				if (
					oldVal.nutOnTop !== newVal.nutOnTop &&
					newVal.nutOnTop === 'No'
				) {
					this.formData.nutOnTopSize = null
				}

				this.$emit('input', newVal)
			},
		},
	},
}
</script>