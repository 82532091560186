<template>
	<div>
		<div class="d-flex mb-4 align-items-center justify-content-between">
			<h3 class="m-0">Register</h3>
			<router-link
				:to="{ name: 'login' }"
				class="text-body text-underline"
				>Log in</router-link
			>
		</div>
		<form-feedback :feedback="feedback"></form-feedback>
		<form @submit.prevent="register">
			<div class="row">
				<div class="col-lg-6">
					<div class="form-group">
						<label class="req">First name</label>
						<input
							v-model="formData.first_name"
							type="text"
							class="form-control"
						/>
					</div>
				</div>
				<div class="col-lg-6">
					<div class="form-group">
						<label class="req">Last name</label>
						<input
							v-model="formData.last_name"
							type="text"
							class="form-control"
						/>
					</div>
				</div>
				<div class="col-lg-6">
					<div class="form-group">
						<label class="req">Email address</label>
						<input
							v-model="formData.email"
							type="email"
							class="form-control"
						/>
					</div>
				</div>
				<div class="col-lg-6">
					<div class="form-group">
						<label>Phone number</label>
						<input
							v-model="formData.phone"
							type="tel"
							class="form-control"
						/>
					</div>
				</div>
				<div class="col-lg-6">
					<div class="form-group">
						<label class="req">Choose password</label>
						<input
							v-model="formData.password"
							type="password"
							class="form-control"
						/>
					</div>
				</div>
				<div class="col-lg-6">
					<div class="form-group">
						<label class="req">Confirm password</label>
						<input
							v-model="formData.password_confirmation"
							type="password"
							class="form-control"
						/>
					</div>
				</div>
				<div class="col-lg-6">
					<div class="form-group">
						<label class="req">Company</label>
						<input
							v-model="formData.company"
							type="text"
							class="form-control"
						/>
					</div>
				</div>
				<div class="col-lg-6">
					<div class="form-group">
						<label class="req">Country</label>
						<input
							v-model="formData.country"
							type="text"
							class="form-control"
						/>
					</div>
				</div>
			</div>
			<button class="btn btn-primary" :disabled="sending">
				<i v-if="sending" class="fa fa-circle-o-notch mr-1 fa-spin"></i>
				Register
			</button>
		</form>
	</div>
</template>

<script>
export default {
	data() {
		return {
			formData: {
				first_name: null,
				last_name: null,
				email: null,
				phone: null,
				password: null,
				password_confirmation: null,
				address: null,
				postal_code: null,
				city: null,
				state: null,
				country: null,
				postcode: null,
				company: null,
				website: null,
			},
			sending: false,
			feedback: {
				contex: null,
				message: null,
				errors: [],
			},
		}
	},
	methods: {
		register() {
			if (this.sending) {
				return
			}

			this.sending = true

			this.$http
				.post('/customers', this.formData)
				.then((res) => {
					this.$router.push({
						name: 'login',
					})
					this.sending = false
				})
				.catch((err) => {
					if (err.response) {
						this.feedback.context = 'danger'
						this.feedback.message = err.response.data.message
						this.feedback.errors = err.response.data.errors
					}
					this.sending = false
				})
		},
	},
}
</script>
