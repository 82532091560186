<template>
	<div v-if="calculation" class="alert alert-info px-8 py-8 mt-8">
		<h4 class="mt-0 mb-3">Calculation results</h4>

		<ul>
			<li v-if="calculation.type == 'slottube'">
				<strong>Open area:</strong>
				<span class="ml-2"
					>{{ (calculation.open_area * 100).toFixed(2) }}%</span
				>
			</li>
			<li v-if="calculation.type == 'slottube'">
				<strong v-if="calculation.flow == 'fito'"
					>Max burst pressure:</strong
				>
				<strong v-else>Collapse strength:</strong>
				<span class="ml-2"
					>{{ (calculation.strength / 1000).toFixed(2) }} bar at
					20°C</span
				>
			</li>
			<li>
				<strong>Delivery time:</strong>
				<span class="ml-2">{{ calculation.delivery_time }} weeks</span>
			</li>
			<li>
				<strong>Unit price:</strong>
				<span class="ml-2"
					>{{ calculation.unit_price.toFixed(2) }} EUR</span
				>
			</li>
			<li>
				<strong>Total price:</strong>
				<span class="ml-2">
					{{
						(calculation.unit_price * calculation.quantity).toFixed(
							2
						)
					}}
					EUR
				</span>
			</li>
		</ul>

		<strong v-if="mailSent">Mail sent!</strong>
		<a
			v-else
			href="#"
			@click.prevent="emailCalculationResults"
			class="btn btn-primary"
			:disabled="mailSending"
		>
			Send results via email
		</a>
	</div>
</template>

<script>
export default {
	props: {
		result: {
			type: [Object, null],
			default() {
				return null
			},
		},
	},
	data() {
		return {
			mailSending: false,
			mailSent: false,
			calculation: this.calculation,
		}
	},
	watch: {
		result: {
			deep: true,
			handler(newVal) {
				this.calculation = newVal
				this.mailSent = false
				this.mailSending = false
			},
		},
	},

	methods: {
		emailCalculationResults() {
			if (this.mailSending) {
				return
			}

			this.mailSending = true

			this.$http
				.post(`/calculations/${this.calculation.uuid}/mail`)
				.then((res) => {
					this.mailSending = false
					this.mailSent = true
				})
				.catch((err) => {
					this.mailSending = false
					console.log(err)
				})
		},
	},
}
</script>