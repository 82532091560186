<template>
	<div>
		<div class="row">
			<div class="col-lg-6">
				<div class="form-group">
					<label class="req"> Number of bottoms </label>
					<div class="input-group mb-3">
						<input
							v-model="formData.numberOfBottoms"
							type="number"
							class="form-control"
						/>
						<div class="input-group-append">
							<span class="input-group-text"> Bottom(s) </span>
						</div>
					</div>
				</div>
			</div>
			<div class="col-lg-6">
				<div class="form-group">
					<label class="req"> Material </label>
					<input
						v-model="formData.material"
						type="text"
						class="form-control"
					/>
				</div>
			</div>
			<div class="col-lg-4">
				<div class="form-group">
					<label class="req"> Outside diameter </label>
					<div class="input-group mb-3">
						<input
							v-model="formData.outsideDiameter"
							type="number"
							class="form-control"
							step="0.01"
						/>
						<div class="input-group-append">
							<span class="input-group-text"> mm </span>
						</div>
					</div>
				</div>
			</div>
			<div class="col-lg-4">
				<div class="form-group">
					<label class="req"> Inside diameter </label>
					<div class="input-group mb-3">
						<input
							v-model="formData.insideDiameter"
							type="number"
							class="form-control"
							step="0.01"
						/>
						<div class="input-group-append">
							<span class="input-group-text"> mm </span>
						</div>
					</div>
				</div>
			</div>
			<div class="col-lg-4">
				<div class="form-group">
					<label class="req"> Slot </label>
					<div class="input-group mb-3">
						<input
							v-model="formData.slot"
							type="number"
							class="form-control"
							step="0.01"
						/>
						<div class="input-group-append">
							<span class="input-group-text"> mm </span>
						</div>
					</div>
				</div>
			</div>
			<div class="col-lg-6">
				<div class="form-group">
					<label class="req"> Number of segments </label>
					<div class="input-group mb-3">
						<input
							v-model="formData.numberOfSegments"
							type="number"
							class="form-control"
						/>
						<div class="input-group-append">
							<span class="input-group-text"> Segment(s) </span>
						</div>
					</div>
				</div>
			</div>
			<div class="col-lg-6">
				<div class="form-group">
					<label class="req"> Or dimension of manhole </label>
					<div class="input-group mb-3">
						<input
							v-model="formData.dimensionOfManhole"
							type="number"
							class="form-control"
							step="0.01"
						/>
						<div class="input-group-append">
							<span class="input-group-text"> mm </span>
						</div>
					</div>
				</div>
			</div>
			<div class="col-lg-6">
				<div class="form-group">
					<label class="req">Construction</label>
					<select
						v-model="formData.constructionType"
						class="form-control"
					>
						<option value="longitudinal_segments">
							Longitudinal segments
						</option>
						<option value="pie_shaped_segments">
							Pie shaped segments
						</option>
					</select>
				</div>
			</div>
			<div class="col-lg-6">
				<div class="form-group">
					<label>
						Profile wire
						<a
							href="/products/wedge-wire/#surface-profiles"
							target="_blank"
						>
							<i class="fa fa-info-circle ml-1"></i>
						</a>
					</label>
					<input
						v-model="formData.profileWire"
						type="text"
						class="form-control"
					/>
				</div>
			</div>
			<div class="col-lg-6">
				<div class="form-group">
					<label>
						Support wire
						<a
							href="/products/wedge-wire/#support-profiles"
							target="_blank"
						>
							<i class="fa fa-info-circle ml-1"></i>
						</a>
					</label>
					<input
						v-model="formData.supportWire"
						type="text"
						class="form-control"
					/>
				</div>
			</div>
			<div class="col-lg-6">
				<div class="form-group">
					<label> Differential pressure </label>
					<input
						v-model="formData.differentialPressure"
						type="text"
						class="form-control"
					/>
				</div>
			</div>
		</div>

		<div class="form-group">
			<label> Application </label>
			<textarea
				v-model="formData.application"
				rows="3"
				class="form-control"
			/>
		</div>

		<div class="form-group">
			<label> Remarks </label>
			<textarea
				v-model="formData.remarks"
				rows="3"
				class="form-control"
			/>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			formData: {},
		}
	},
	watch: {
		formData: {
			deep: true,
			handler(newVal, oldVal) {
				if (oldVal.segments !== newVal.segments) {
					this.formData.manholeDimensions = null
				}

				if (oldVal.manholeDimensions !== newVal.manholeDimensions) {
					this.formData.segments = null
				}

				this.$emit('input', newVal)
			},
		},
	},
}
</script>