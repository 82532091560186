<template>
	<div>
		<div class="form-group">
			<label class="req">Direction</label>
			<select v-model="formData.direction" class="form-control">
				<option value="radial_external_wire_foti">
					Radial external wire FOTI
				</option>
				<option value="radial_external_wire_fito">
					Radial external wire FITO
				</option>
				<option value="axial_internal_wire_foti">
					Axial internal wire FOTI
				</option>
				<option value="axial_internal_wire_fito">
					Axial internal wire FITO
				</option>
				<option value="radial_internal_wire_foti">
					Radial internal wire FOTI
				</option>
				<option value="radial_internal_wire_fito">
					Radial internal wire FITO
				</option>
			</select>
		</div>

		<div class="form-group">
			<label class="req"> Quantity </label>
			<div class="input-group mb-3">
				<input
					v-model="formData.quantity"
					type="number"
					class="form-control"
				/>
				<div class="input-group-append">
					<span class="input-group-text"> Piece(s) </span>
				</div>
			</div>
		</div>

		<div class="form-group">
			<label class="req"> Material </label>
			<input
				v-model="formData.material"
				type="text"
				class="form-control"
			/>
		</div>

		<div
			v-if="
				['radial_ext_wire_foti', 'radial_ext_wire_fito'].includes(
					formData.direction
				)
			"
			class="form-group"
		>
			<label class="req"> Outside diameter </label>
			<div class="input-group mb-3">
				<input
					v-model="formData.outsideDiameter"
					type="number"
					class="form-control"
					step="0.01"
				/>
				<div class="input-group-append">
					<span class="input-group-text"> mm </span>
				</div>
			</div>
		</div>

		<div
			v-if="
				[
					'axial_internal_wire_fito',
					'radial_internal_wire_fito',
				].includes(formData.direction)
			"
			class="form-group"
		>
			<label class="req"> Inside diameter </label>
			<div class="input-group mb-3">
				<input
					v-model="formData.insideDiameter"
					type="number"
					class="form-control"
					step="0.01"
				/>
				<div class="input-group-append">
					<span class="input-group-text"> mm </span>
				</div>
			</div>
		</div>

		<div class="form-group">
			<label class="req"> Overall length </label>
			<div class="input-group mb-3">
				<input
					v-model="formData.overallLength"
					type="number"
					class="form-control"
					step="0.01"
				/>
				<div class="input-group-append">
					<span class="input-group-text"> mm </span>
				</div>
			</div>
		</div>

		<div class="form-group">
			<label class="req"> Slot </label>
			<div class="input-group mb-3">
				<input
					v-model="formData.slot"
					type="number"
					class="form-control"
					step="0.01"
				/>
				<div class="input-group-append">
					<span class="input-group-text"> mm </span>
				</div>
			</div>
		</div>

		<div class="form-group">
			<label>
				Profile wire
				<a
					href="/products/wedge-wire/#support-profiles"
					target="_blank"
				>
					<i class="fa fa-info-circle ml-1"></i>
				</a>
			</label>
			<input
				v-model="formData.profileWire"
				type="text"
				class="form-control"
			/>
		</div>

		<div class="form-group">
			<label>
				Support wire
				<a
					href="/products/wedge-wire/#surface-profiles"
					target="_blank"
				>
					<i class="fa fa-info-circle ml-1"></i>
				</a>
			</label>
			<input
				v-model="formData.supportWire"
				type="text"
				class="form-control"
			/>
		</div>

		<div class="form-group">
			<label> End pieces one end </label>
			<input
				v-model="formData.endPieces1"
				type="text"
				class="form-control"
			/>
		</div>

		<div class="form-group">
			<label> End pieces other end </label>
			<input
				v-model="formData.endPieces2"
				type="text"
				class="form-control"
			/>
		</div>

		<div class="form-group">
			<label>Differential pressure</label>
			<input
				v-model="formData.differentialPressure"
				type="text"
				class="form-control"
			/>
		</div>

		<div class="form-group">
			<label> Remarks </label>
			<textarea
				v-model="formData.remarks"
				rows="3"
				class="form-control"
			/>
		</div>
	</div>
</template>


<script>
export default {
	data() {
		return {
			formData: {},
		}
	},
	watch: {
		formData: {
			deep: true,
			handler(newVal, oldVal) {
				if (oldVal.segments !== newVal.segments) {
					console.log(oldVal.segments)
					this.formData.manholeDimensions = null
				}

				if (oldVal.manholeDimensions !== newVal.manholeDimensions) {
					this.formData.segments = null
				}

				this.$emit('input', newVal)
			},
		},
	},
}
</script>