import Vue from 'vue'
import Router from 'vue-router'
import Index from './views/Index.vue'
import Login from './views/Login.vue'
import Logout from './views/Logout.vue'
import Register from './views/Register.vue'
import SlotTube from './views/SlotTube.vue'
import FlatScreen from './views/FlatScreen.vue'
import Inquiry from './views/Inquiry.vue'

Vue.use(Router)

const router = new Router({
    routes: [
        {
            path: '/',
            name: 'index',
            component: Index,
        },
        {
            path: '/login',
            name: 'login',
            component: Login,
            meta: {
                allowAnonymous: true,
            },
        },
        {
            path: '/logout',
            name: 'logout',
            component: Logout,
        },
        {
            path: '/register',
            name: 'register',
            component: Register,
            meta: {
                allowAnonymous: true,
            },
        },
        {
            path: '/slot-tube-calculator',
            name: 'slot-tube',
            component: SlotTube,
        },
        {
            path: '/flat-screen-calculator',
            name: 'flat-screen',
            component: FlatScreen,
        },
        {
            path: '/inquiry',
            name: 'inquiry',
            component: Inquiry,
        },
    ],
    base: '/calculator/',
    mode: 'history',
    linkActiveClass: 'active',
})

router.beforeEach((to, from, next) => {
    if (!to.meta.allowAnonymous && !localStorage.getItem('token')) {
        next({
            name: 'login',
            query: { redirect: to.fullPath },
        })
    } else if (to.meta.allowAnonymous && localStorage.getItem('token')) {
        next({
            name: 'index',
            query: { redirect: to.fullPath },
        })
    } else {
        next()
    }
})

export default router
