<template>
	<div>
		<h3>Slot tube</h3>

		<calculation-credits />

		<calculation-result :result="calculation"> </calculation-result>

		<form-feedback :feedback="feedback" />

		<!-- Form -->
		<form
			v-if="
				user &&
				(user.calculation_credits > 0 ||
					user.calculation_credits === null)
			"
			@submit.prevent="calculate"
		>
			<div class="row">
				<div class="col-lg-4">
					<div class="form-group">
						<label>Diameter</label>
						<div class="input-group">
							<input
								v-model="formData.diameter"
								type="number"
								class="form-control"
								@change="
									checkMinimumSlotWidth()
									fetchSurfaceProfiles()
									fetchSupportQuantities()
									formData.supportProfile = null
									formData.surfaceProfile = null
									formData.supportQuantity = null
								"
							/>
							<div class="input-group-append">
								<span class="input-group-text">mm</span>
							</div>
						</div>
					</div>
				</div>
				<div class="col-lg-4">
					<div class="form-group">
						<label>Slot width</label>
						<div class="input-group">
							<input
								v-model="formData.slotWidth"
								type="number"
								class="form-control"
								step="0.01"
								@change="checkMinimumSlotWidth()"
							/>
							<div class="input-group-append">
								<span class="input-group-text">mm</span>
							</div>
						</div>
					</div>
				</div>
				<div class="col-lg-4">
					<div class="form-group">
						<label>Length</label>
						<div class="input-group">
							<input
								v-model="formData.length"
								type="number"
								class="form-control"
							/>
							<div class="input-group-append">
								<span class="input-group-text">mm</span>
							</div>
						</div>
					</div>
				</div>
				<div class="col-lg-6">
					<div class="form-group">
						<label>Flow</label>
						<select
							v-model="formData.flow"
							class="form-control"
							@change="checkMinimumSlotWidth"
						>
							<option
								v-for="(flow, flowIndex) in flows"
								:key="flowIndex"
								:value="flow.name"
							>
								{{ flow.label }}
							</option>
						</select>
					</div>
				</div>
				<div class="col-lg-6">
					<div class="form-group">
						<label>Quantity</label>
						<input
							v-model="formData.quantity"
							type="number"
							class="form-control"
						/>
					</div>
				</div>
				<div class="col-lg-6">
					<div class="form-group">
						<label>Surface profile</label>
						<select
							v-model="formData.surfaceProfile"
							class="form-control"
							:disabled="formData.diameter == null"
						>
							<option
								v-for="(
									surfaceProfile, surfaceProfileIndex
								) in surfaceProfiles"
								:key="surfaceProfileIndex"
								:value="surfaceProfile.uuid"
							>
								{{ surfaceProfile.name }}
							</option>
						</select>
					</div>
				</div>
				<div class="col-lg-6">
					<div class="form-group">
						<label>Surface material</label>
						<select
							v-model="formData.surfaceMaterial"
							class="form-control"
							:disabled="formData.surfaceProfile == null"
						>
							<option
								v-for="(
									surfaceMaterial, surfaceMaterialIndex
								) in surfaceMaterials"
								:key="surfaceMaterialIndex"
								:value="surfaceMaterial.uuid"
							>
								{{ surfaceMaterial.internal_name }}
							</option>
						</select>
					</div>
				</div>
				<div class="col-lg-6">
					<div class="form-group">
						<label>Support profile</label>
						<select
							v-model="formData.supportProfile"
							class="form-control"
							:disabled="formData.diameter == null"
						>
							<option
								v-for="(
									supportProfile, supportProfileIndex
								) in supportProfiles"
								:key="supportProfileIndex"
								:value="supportProfile.uuid"
							>
								{{ supportProfile.name }}
							</option>
						</select>
					</div>
				</div>
				<div class="col-lg-6">
					<div class="form-group">
						<label>Support material</label>
						<select
							v-model="formData.supportMaterial"
							class="form-control"
							:disabled="formData.supportProfile == null"
						>
							<option
								v-for="(
									supportMaterial, supportMaterialIndex
								) in supportMaterials"
								:key="supportMaterialIndex"
								:value="supportMaterial.uuid"
							>
								{{ supportMaterial.internal_name }}
							</option>
						</select>
					</div>
				</div>
			</div>

			<div class="form-group">
				<label>Support quantity</label>
				<select
					v-model="formData.supportQuantity"
					class="form-control"
					:disabled="formData.diameter == null"
				>
					<option
						v-for="(
							supportQuantity, supportQuantityIndex
						) in supportQuantities"
						:key="supportQuantityIndex"
						:value="supportQuantity"
					>
						{{ supportQuantity }}
					</option>
				</select>
			</div>

			<button
				:disabled="calculating"
				class="btn btn-primary"
				type="submit"
			>
				Calculate
			</button>
		</form>
		<!-- End Form -->
	</div>
</template>

<script>
import CalculationCredits from '../components/CalculationCredits.vue'
export default {
	components: { CalculationCredits },
	computed: {
		user() {
			return this.$store.getters.user
		},

		chosenSurfaceMaterial() {
			return this.formData.surfaceMaterial
		},

		chosenSupportMaterial() {
			return this.formData.supportMaterial
		},

		chosenSurfaceProfile() {
			return this.formData.surfaceProfile
		},

		chosenSupportProfile() {
			return this.formData.supportProfile
		},

		chosenDiameter() {
			return this.formData.diameter
		},

		chosenSlotWidth() {
			return this.formData.slotWidth
		},

		minimumSlotWidth() {
			if (!this.formData.diameter) {
				return
			}

			if (!this.formData.flow) {
				return
			}

			if (this.formData.flow === 'foti') {
				// minimum spleet FOTI tot diam 301 : 0,03
				if (this.formData.diameter <= 301) {
					return 0.03
				}

				// minimum spleet FOTI tot diam 505 : 0,05
				if (this.formData.diameter <= 505) {
					return 0.05
				}

				return 0.15
			}

			if (this.formData.flow === 'fito') {
				// minimum spleet FITO tot diam 301 : 0,05
				if (this.formData.diameter <= 301) {
					return 0.05
				}

				// minimum spleet FITO tot diam 505 : 0,1
				if (this.formData.diameter <= 505) {
					return 0.1
				}

				// minimum spleet FITO vanaf diam 506 : 0,15
				return 0.15
			}
		},
	},
	watch: {
		chosenSurfaceProfile(newVal) {
			this.formData.surfaceMaterial = null

			if (newVal) {
				this.fetchSurfaceMaterials()
				this.fetchSupportProfiles()
			}
		},

		chosenSupportProfile(newVal) {
			this.formData.supportMaterial = null

			if (newVal) {
				this.fetchSupportMaterials()
			}
		},

		formData: {
			deep: true,
			handler() {
				this.calculation = null
			},
		},
	},
	data() {
		return {
			calculating: false,
			formData: {
				diameter: null,
				length: null,
				flow: null,
				quantity: null,
				surfaceProfile: null,
				supportProfile: null,
				surfaceMaterial: null,
				supportMaterial: null,
				supportQuantity: null,
				slotWidth: null,
			},
			flows: null,
			surfaceProfiles: null,
			supportProfiles: null,
			surfaceMaterials: null,
			supportMaterials: null,
			supportQuantities: null,
			feedback: {
				context: null,
				message: null,
				errors: [],
			},
			calculation: null,
		}
	},
	mounted() {
		this.fetchFlows()
	},
	methods: {
		calculate() {
			if (this.calculating) {
				return
			}

			this.calculating = true

			this.$http
				.post('/calculators/slot-tube', {
					surface_profile: this.formData.surfaceProfile,
					surface_material: this.formData.surfaceMaterial,
					support_profile: this.formData.supportProfile,
					support_material: this.formData.supportMaterial,
					diameter: this.formData.diameter,
					length: this.formData.length,
					slot_width: this.formData.slotWidth,
					flow: this.formData.flow,
					quantity: this.formData.quantity,
					support_quantity: this.formData.supportQuantity,
				})
				.then((res) => {
					this.feedback.context = null
					this.feedback.message = null
					this.feedback.errors = []
					this.calculation = res.data.calculation
					this.calculating = false
				})
				.catch((err) => {
					this.feedback.context = 'danger'
					this.feedback.message = err.response.data.message
					this.feedback.errors = err.response.data.errors
					this.calculating = false
				})
		},

		fetchFlows() {
			this.$http
				.get('/flows')
				.then((res) => {
					this.flows = res.data
				})
				.catch((err) => {
					console.log(err)
					alert('Could not fetch flows.')
				})
		},

		fetchSurfaceProfiles() {
			if (!this.formData.diameter) {
				return
			}

			this.$http
				.get(
					`/surface-profiles?filter[eligible_for_diameter]=${this.formData.diameter}`
				)
				.then((res) => {
					this.surfaceProfiles = res.data.data
				})
				.catch((err) => {
					console.log(err)
					alert('Could not fetch surface profiles.')
				})
		},

		fetchSupportProfiles() {
			if (!this.formData.diameter) {
				return
			}

			this.$http
				.get(
					`/support-profiles?filter[eligible_for_diameter]=${this.formData.diameter}&filter[eligible_for_surface_profile]=${this.formData.slotWidth}`
				)
				.then((res) => {
					this.supportProfiles = res.data.data
				})
				.catch((err) => {
					console.log(err)
					alert('Could not fetch support profiles.')
				})
		},

		fetchSurfaceMaterials() {
			this.$http
				.get(`/profiles/${this.formData.surfaceProfile}/materials`)
				.then((res) => {
					this.surfaceMaterials = res.data.data
				})
				.catch((err) => {
					console.log(err)
					alert(
						'Could not fetch surface materials for the given surface profile.'
					)
				})
		},

		fetchSupportMaterials() {
			this.$http
				.get(`/profiles/${this.formData.supportProfile}/materials`)
				.then((res) => {
					this.supportMaterials = res.data.data
				})
				.catch((err) => {
					console.log(err)
					alert(
						'Could not fetch support materials for the given support profile.'
					)
				})
		},

		fetchSupportQuantities() {
			if (!this.formData.diameter) {
				return
			}

			this.$http
				.post('/support-quantities', {
					diameter: this.formData.diameter,
				})
				.then((res) => {
					this.supportQuantities = res.data
				})
				.catch((err) => {
					console.log(err)
					alert(
						'Could not fetch support quantities for the given diameter.'
					)
				})
		},

		checkMinimumSlotWidth() {
			if (!this.minimumSlotWidth) {
				return
			}

			if (!this.formData.slotWidth) {
				return
			}

			if (this.formData.slotWidth >= this.minimumSlotWidth) {
				return
			}

			window.alert(`Minimum slot width: ${this.minimumSlotWidth}`)
			this.formData.slotWidth = this.minimumSlotWidth
		},
	},
}
</script>
