import axios from 'axios'
import router from './router'

var http = axios.create({
    baseURL: 'https://app.trislot.site/api',
    // baseURL: 'http://tri.test/api',
})

if (localStorage.getItem('token')) {
    http.defaults.headers.common = {
        Authorization: `bearer ${localStorage.getItem('token')}`,
        Accept: 'application/json'
    }
}

http.interceptors.response.use(null, function (error) {
    if (error.response) {
        if (error.response.status === 401) {
            router.push({ name: 'logout' })
            // console.log('Logging out...')
        }
    }

    return Promise.reject(error)
})

export default http
