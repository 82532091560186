<template>
	<div
		v-if="user && user.calculation_credits !== null"
		class="alert alert-warning"
	>
		You have {{ user.calculation_credits }} calculations remaining. Please
		verify your email address to enable more.
	</div>
</template>

<script>
export default {
	computed: {
		user() {
			return this.$store.getters.user
		},
	},
}
</script>
