<template>
	<div>
		<div class="form-group">
			<label> Diameter vessel </label>
			<div class="input-group mb-3">
				<input
					v-model="formData.diameterVessel"
					type="number"
					class="form-control"
					step="0.01"
				/>
				<div class="input-group-append">
					<span class="input-group-text"> mm </span>
				</div>
			</div>
		</div>

		<div class="row">
			<div class="col-lg-4">
				<div class="form-group">
					<label class="req"> Flow rate </label>
					<div class="input-group mb-3">
						<input
							v-model="formData.flowRate"
							type="number"
							class="form-control"
							step="0.01"
						/>
						<div class="input-group-append">
							<span class="input-group-text">
								m<sup>3</sup>/h
							</span>
						</div>
					</div>
				</div>
			</div>
			<div class="col-lg-4">
				<div class="form-group">
					<label class="req">
						Number of sets (header + slot tubes)
					</label>
					<div class="input-group mb-3">
						<input
							v-model="formData.numberOfSets"
							type="number"
							class="form-control"
						/>
						<div class="input-group-append">
							<span class="input-group-text"> sets </span>
						</div>
					</div>
				</div>
			</div>
			<div class="col-lg-4">
				<div class="form-group">
					<label class="req"> Material </label>
					<input
						v-model="formData.material"
						type="text"
						class="form-control"
					/>
				</div>
			</div>
			<div class="col-12">
				<div class="form-group">
					<label class="req">Construction header</label>
					<select
						v-model="formData.constructionHeader"
						class="form-control"
					>
						<option value="horizontal">
							Horizontal central tube with slot tubes arranged
							laterally
						</option>
						<option value="vertical">
							Vertical tube with slottubes arranged radially
						</option>
					</select>
				</div>

				<div
					v-if="formData.constructionHeader == 'horizontal'"
					class="form-group"
				>
					<label class="req">Position of connection flange</label>
					<select
						v-model="formData.connectionFlangePosition"
						class="form-control"
					>
						<option value="Central">Central</option>
						<option value="At one side of the header">
							At one side of the header
						</option>
					</select>
				</div>
			</div>
			<div class="col-lg-4">
				<div
					v-if="formData.constructionHeader == 'vertical'"
					class="form-group"
				>
					<label class="req"> Diameter </label>
					<div class="input-group mb-3">
						<input
							v-model="formData.diameter"
							type="number"
							class="form-control"
							step="0.01"
						/>
						<div class="input-group-append">
							<span class="input-group-text"> mm </span>
						</div>
					</div>
				</div>
			</div>
			<div class="col-lg-4">
				<div
					v-if="formData.constructionHeader == 'vertical'"
					class="form-group"
				>
					<label class="req"> Height </label>
					<div class="input-group mb-3">
						<input
							v-model="formData.height"
							type="number"
							class="form-control"
							step="0.01"
						/>
						<div class="input-group-append">
							<span class="input-group-text"> mm </span>
						</div>
					</div>
				</div>
			</div>
			<div class="col-lg-4">
				<div
					v-if="formData.constructionHeader == 'vertical'"
					class="form-group"
				>
					<label class="req">
						Dimension of the connection flange
					</label>
					<div class="input-group mb-3">
						<input
							v-model="formData.dimensionConnectionFlange"
							type="number"
							class="form-control"
							step="0.01"
						/>
						<div class="input-group-append">
							<span class="input-group-text"> mm </span>
						</div>
					</div>
				</div>
			</div>
			<div class="col-lg-6">
				<div class="form-group">
					<label> Number of slot tubes </label>
					<div class="input-group mb-3">
						<input
							v-model="formData.numberOfSlotTubes"
							type="number"
							class="form-control"
						/>
						<div class="input-group-append">
							<span class="input-group-text"> pieces </span>
						</div>
					</div>
				</div>
			</div>
			<div class="col-lg-6">
				<div class="form-group">
					<label class="req">Slot tube construction</label>
					<select
						v-model="formData.constructionSlotTube"
						class="form-control"
					>
						<option value="no_perforated_tube_inside">
							No perforated tube inside
						</option>
						<option value="perforated_tube_inside">
							Perforated tube inside
						</option>
					</select>
				</div>
			</div>
			<div class="col-lg-4">
				<div
					v-if="
						formData.constructionSlotTube ==
						'perforated_tube_inside'
					"
					class="form-group"
				>
					<label class="req"> Outside diameter slot tube </label>
					<div class="input-group mb-3">
						<input
							v-model="formData.outsideDiameterSlotTube"
							type="number"
							class="form-control"
							step="0.01"
						/>
						<div class="input-group-append">
							<span class="input-group-text"> mm </span>
						</div>
					</div>
				</div>
			</div>
			<div class="col-lg-4">
				<div
					v-if="
						formData.constructionSlotTube ==
						'perforated_tube_inside'
					"
					class="form-group"
				>
					<label class="req">
						Outside diameter perforated tube
					</label>
					<div class="input-group mb-3">
						<input
							v-model="formData.outsideDiameterPerforatedTube"
							type="number"
							class="form-control"
							step="0.01"
						/>
						<div class="input-group-append">
							<span class="input-group-text"> mm </span>
						</div>
					</div>
				</div>
			</div>
			<div class="col-lg-4">
				<div
					v-if="
						formData.constructionSlotTube ==
						'perforated_tube_inside'
					"
					class="form-group"
				>
					<label class="req"> Slot </label>
					<div class="input-group mb-3">
						<input
							v-model="formData.slot"
							type="number"
							class="form-control"
							step="0.01"
						/>
						<div class="input-group-append">
							<span class="input-group-text"> mm </span>
						</div>
					</div>
				</div>
			</div>
			<div class="col-lg-6">
				<div class="form-group">
					<label> Number of holes </label>
					<input
						v-model="formData.numberOfHoles"
						type="text"
						class="form-control"
					/>
				</div>
			</div>
			<div class="col-lg-6">
				<div class="form-group">
					<label> Diameter of holes </label>
					<div class="input-group mb-3">
						<input
							v-model="formData.diameterOfHoles"
							type="number"
							class="form-control"
							step="0.01"
						/>
						<div class="input-group-append">
							<span class="input-group-text"> mm </span>
						</div>
					</div>
				</div>
			</div>
			<div class="col-lg-6">
				<div class="form-group">
					<label> Connection to header </label>
					<select
						v-model="formData.connectionToHeader"
						class="form-control"
					>
						<option value="flange">Flange</option>
						<option value="threaded_fitting">
							Threaded fitting
						</option>
					</select>
				</div>
			</div>
			<div class="col-lg-6">
				<div class="form-group">
					<label> Dimensions </label>
					<input
						v-model="formData.connectionToHeaderConnections"
						type="text"
						class="form-control"
					/>
				</div>
			</div>
		</div>
		<div class="form-group">
			<label> Remarks </label>
			<textarea
				v-model="formData.remarks"
				rows="3"
				class="form-control"
			/>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			formData: {},
		}
	},
	watch: {
		formData: {
			deep: true,
			handler(newVal, oldVal) {
				if (
					oldVal.constructionSlotTube !== newVal.constructionSlotTube
				) {
					this.formData.outsideDiameterSlotTube = null
					this.formData.outsideDiameterPerforatedTube = null
					this.formData.slot = null
				}

				if (oldVal.constructionHeader !== newVal.constructionHeader) {
					this.formData.connectionFlangePosition = null
					this.formData.diameter = null
					this.formData.height = null
					this.formData.dimensionConnectionFlange = null
				}

				this.$emit('input', newVal)
			},
		},
	},
}
</script>