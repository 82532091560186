<template>
	<div>
		<div class="row">
			<div class="col-lg-6">
				<div class="form-group">
					<label> Quantity </label>
					<input
						v-model="formData.quantity"
						type="number"
						class="form-control"
					/>
				</div>
			</div>
			<div class="col-lg-6">
				<div class="form-group">
					<label> Flow </label>
					<select v-model="formData.flow" class="form-control">
						<option value="FITO">Flow in to out</option>
						<option value="FOTI">Flow out to in</option>
					</select>
				</div>
			</div>
			<div class="col-lg-6">
				<div class="form-group">
					<label>
						<span v-if="formData.flow == 'FITO'"> Inside </span>
						<span v-else> Outside </span>
						diameter large side
					</label>
					<div class="input-group mb-3">
						<input
							v-model="formData.diameterLargeSide"
							type="number"
							class="form-control"
							step="0.01"
						/>
						<div class="input-group-append">
							<span class="input-group-text"> mm </span>
						</div>
					</div>
				</div>
			</div>
			<div class="col-lg-6">
				<div class="form-group">
					<label>
						<span v-if="formData.flow == 'FITO'"> Inside </span>
						<span v-else> Outside </span>
						diameter small side
					</label>
					<div class="input-group mb-3">
						<input
							v-model="formData.diameterSmallSide"
							type="number"
							class="form-control"
							step="0.01"
						/>
						<div class="input-group-append">
							<span class="input-group-text"> mm </span>
						</div>
					</div>
				</div>
			</div>
			<div class="col-lg-4">
				<div class="form-group">
					<label> Material </label>
					<input
						v-model="formData.material"
						type="text"
						class="form-control"
					/>
				</div>
			</div>
			<div class="col-lg-4">
				<div class="form-group">
					<label> Slot </label>
					<div class="input-group mb-3">
						<input
							v-model="formData.slot"
							type="number"
							class="form-control"
							step="0.01"
						/>
						<div class="input-group-append">
							<span class="input-group-text"> mm </span>
						</div>
					</div>
				</div>
			</div>
			<div class="col-lg-4">
				<div class="form-group">
					<label> Height or angle </label>
					<input
						v-model="formData.heightOrAngle"
						type="text"
						class="form-control"
					/>
				</div>
			</div>
			<div class="col-lg-6">
				<div class="form-group">
					<label>
						Profile wire
						<a
							href="/products/wedge-wire/#surface-profiles"
							target="_blank"
						>
							<i class="fa fa-info-circle ml-1"></i>
						</a>
					</label>
					<input
						v-model="formData.profileWire"
						type="text"
						class="form-control"
					/>
				</div>
			</div>
			<div class="col-lg-6">
				<div class="form-group">
					<label>
						Support wire
						<a
							href="/products/wedge-wire/#support-profiles"
							target="_blank"
						>
							<i class="fa fa-info-circle ml-1"></i>
						</a>
					</label>
					<input
						v-model="formData.supportWire"
						type="text"
						class="form-control"
					/>
				</div>
			</div>
			<div class="col-lg-6">
				<div class="form-group">
					<label> End pieces one end </label>
					<input
						v-model="formData.endPiecesOneEnd"
						type="text"
						class="form-control"
					/>
				</div>
			</div>
			<div class="col-lg-6">
				<div class="form-group">
					<label> End pieces other end </label>
					<input
						v-model="formData.endPiecesOtherEnd"
						type="text"
						class="form-control"
					/>
				</div>
			</div>
			<div class="col-12">
				<div class="form-group">
					<label> Remarks </label>
					<textarea
						v-model="formData.remarks"
						rows="3"
						class="form-control"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			formData: {
				quantity: null,
				material: null,
				profileLength: null,
				width: null,
				slot: null,
				constructionType: null,
				radius: null,
				profileWire: null,
				supportWire: null,
				pitch: null,
				parallelToProfileWire: null,
				perpendicularToProfileWire: null,
				remarks: null,
			},
		}
	},
	watch: {
		formData: {
			deep: true,
			handler(newVal, oldVal) {
				this.$emit('input', newVal)
			},
		},
	},
}
</script>