import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        token: localStorage.getItem('token') || '',
        user: null,
    },
    getters: {
        user(state) {
            return state.user
        },

        token(state) {
            return state.token
        },

        isAuthenticated(state) {
            return state.token !== ''
        },
    },
    mutations: {
        login(state, payload) {
            state.token = payload.token
            state.user = payload.user
            Vue.prototype.$http.defaults.headers.common['Authorization'] =
                'Bearer ' + payload.token
            localStorage.setItem('token', payload.token)
        },

        setUser(state, payload) {
            state.user = payload
        },

        logout(state) {
            state.user = null
            state.token = ''
            Vue.prototype.$http.defaults.headers.common['Authorization'] = ''
            localStorage.removeItem('token')
        },
    },
})
